import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const MeetTheMentors = () => (
  <Layout title="Meet the Mentors" className="annual-challenge has-video">
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">Meet the Mentors</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Meet the Mentors</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../journey-to-3m" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <div className="videoWrapper">
                <Video
                  guidSrc="0eb7bd74-6bc0-42d9-94e8-7dc3d42c56dc"
                  className="de-vid"
                ></Video>
              </div>
              <Link to="../voyage-of-ideas" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <h3>
              3M scientists are true innovators – and the best mentors – you’ll
              ever meet. Get to know a few now.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default MeetTheMentors;
